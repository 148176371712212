table {
  border-collapse: collapse;
}

td,
th {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding: 4px !important;
  text-align: center;
  vertical-align: middle;
  min-width: 1em;
  max-width: 4em;
  background-color: transparent !important;
  font-size: calc(5px + 3vmin);
  font-weight: normal;
}

th.monthHeader {
  text-align: left;
  vertical-align: bottom;
  font-size: 0.6em;
}

td.blank {
  border-bottom: none;
}

.dateNav {
  display: flex;
  justify-content: space-between;
}

table td:first-child { /* Controls the first column */
  min-width: 18vmin;
  word-wrap: break-word;
}

table tr:last-child td {
  border-bottom: none;
}

tr td.highlightedCell,
tr th.highlightedCell,
.highlightedCell {
  border: 2px solid black;
  font-weight: bold;
}