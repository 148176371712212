.App {
  text-align: center;
  background-color: rgb(30, 35, 45);
  color: white;
  display: flex;
  margin: auto;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-header {
  background-color: rgb(30, 35, 45);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.formErrorText {
  color: red
}