.highlightedDotButton,
.dotButtonOption,
.dotButton {
  min-width: calc(8vmin);
  min-height: calc(8vmin);
  font-size: calc(5px + 4vmin);
  text-align: center;
  padding: 1px;
}

.highlightedDotButton {
  border: 2px solid black;
}

.dotButtonOption {
  min-width: calc(20px + 4vmin);
  min-height: calc(20px + 4vmin);
  font-size: calc(10px + 3vmin);
  margin: calc(5px);
}

.popover {
  background-color: rgb(30, 35, 45);
  border: 1px solid rgb(255, 255, 255);
}
.popover0to5or9to10 {
  max-width: calc(100px + (20px + 4vmin) * 5); /* Wide enough to fit the max of 5 icons plus space*/
}
.popover6 {
  max-width: calc(100px + (20px + 4vmin) * 3); /* Wide enough to fit the max of 5 icons plus space*/
}
.popover7or8 {
  max-width: calc(100px + (20px + 4vmin) * 4); /* Wide enough to fit the max of 5 icons plus space*/
}

.popover-body {
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}